import { Component, Inject, OnInit } from "@angular/core";
import { webcam } from "./index";

import { DOCUMENT } from "@angular/common";
@Component({
  selector: "app-telemedicine",
  templateUrl: "./telemedicine.component.html",
  styleUrls: ["./telemedicine.component.css"],
})
export class TelemedicineComponent implements OnInit {
  constructor(@Inject(DOCUMENT) document: Document) {}

  ngOnInit(): void {}

  join() {
   // config.join();
  }
  create() {
   // config.create();
  }

  leave() {
   // config.exit();
  }

  mic() {
    //config.micr();
  }
  webcam() {
    //webcam()
  }
}
