import { FooterComponent } from "./landing/footer/footer.component";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { MDBBootstrapModule } from "angular-bootstrap-md";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { MatInputModule } from "@angular/material/input";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatButtonModule } from "@angular/material/button";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatTableModule } from "@angular/material/table";
import {MatSidenavModule} from '@angular/material/sidenav';
import { MatTooltipModule } from "@angular/material/tooltip";
import { NgxMaskModule } from "ngx-mask";
import { MatSelectModule } from "@angular/material/select";
import { MatExpansionModule } from "@angular/material/expansion";
import { DatePipe, registerLocaleData } from "@angular/common";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { LOCALE_ID } from "@angular/core";
import localePt from "@angular/common/locales/pt";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatTabsModule } from "@angular/material/tabs";
import { MatIconModule } from "@angular/material/icon";
import { MatSnackBarModule } from "@angular/material/snack-bar";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { NavbarScreenComponent } from "./navbar/navbar.component";
import { PatientsComponent } from "./patients/patients.component";
import { PatientComponent } from "./patient/patient.component";
import { ModalPatientDetailComponent } from "./modals/modal-patient-detail/modal-patient-detail.component";
import { ModalMessageComponent } from "./modals/modal-message/modal-message.component";
import { ModalChoiceComponent } from "./modals/modal-choice/modal-choice.component";
import { DoctorsComponent } from "./doctors/doctors.component";
import { ModalDoctorDetailComponent } from "./modals/modal-doctor-detail/modal-doctor-detail.component";
import { InformationsComponent } from "./informations/informations.component";
import { ModalSpecialtyComponent } from "./informations/specialty/modal-specialty.component";
import { ModalAssociationComponent } from "./informations/association/modal-association.component";
import { MedicationComponent } from "./medication/medication.component";
import { ModalMedicationDetailComponent } from "./modals/modal-medication-detail/modal-medication-detail.component";
import { TermsComponent } from "./terms/terms.component";
import { ModalPatientMedicationDetailComponent } from "./modals/modal-patient-medication-detail/modal-patient-medication-detail.component";
import { ModalNewPatientMedicationComponent } from "./modals/modal-new-patient-medication/modal-new-patient-medication.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { DeleteAccountComponent } from "./delete-account/delete-account.component";
import { HomeComponent } from "./landing/home/home.component";
import { LandingComponent } from "./landing/landing.component";
import { AboutComponent } from "./landing/about/about.component";
import { ContactUsComponent } from "./landing/contact-us/contact-us.component";
import { HomePatientComponent } from "./landing/home-patient/home-patient.component";
import { HomeDoctorComponent } from "./landing/home-doctor/home-doctor.component";
import { HomecareComponent } from "./landing/homecare/homecare.component";
import { PlansComponent } from "./landing/plans/plans.component";
import { PartnersComponent } from "./landing/partners/partners.component";
import { ModalDoctorCheckinComponent } from "./modals/modal-doctor-checkin/modal-doctor-checkin.component";
import { FormsComponent } from "./forms/forms.component";
import { ModalFormDetailComponent } from "./modals/modal-form-detail/modal-form-detail.component";
import { ModalFormAnswerComponent } from "./modals/modal-form-answer/modal-form-answer.component";
import { TermsRawComponent } from "./terms/terms-raw.component";
import { SinaisVitaisComponent } from "./patient/components/sinais-vitais/sinais-vitais.component";
import { MedicamentosComponent } from "./patient/components/medicamentos/medicamentos.component";
import { ExamesComponent } from "./patient/components/exames/exames.component";
import { FormularioComponent } from "./patient/components/formularios/formulario.component";
import { ReportDayComponent } from "./report-day/report_day.component";
import { ModalAddRecordComponent } from "./modals/modal-add-record/modal-add-record.component";
import { ModalAddMedicationComponent } from "./modals/modal-add-medication/modal-add-medication.component";
import { ModalAddExamComponent } from "./modals/modal-add-exam/modal-add-exam.component";
import { ModalAddPatientComponent } from "./modals/modal-edit-patient/modal-add-patient.component";
import { ModalFormFillComponent } from "./modals/modal-form-fill/modal-form-fill.component";
import { PrescricaoComponent } from "./patient/components/prescricoes/prescricoes.component";
import { PrescricoesService } from "./patient/components/prescricoes/prescicoes.service";
import { ModalSelectFormComponent } from "./modals/modal-select-form/modal-select-form.component";
import { ModalFillFormComponent } from "./modals/modal-fill-form/modal-fill-form.component";
import { FormQuestionSelectComponent } from "./modals/modal-fill-form/form-question-select.component";
import { FormQuestionComponent } from "./modals/modal-fill-form/form-question.component";
import { ModalFormCreateComponent } from "./modals/modal-form-create/modal-form-create.component";
import { ModalFormCreateQuestionComponent } from "./modals/modal-form-create-question/modal-form-create-question.component";
import { ModalFormCreateQuestionAlternativeComponent } from "./modals/modal-form-create-question-alternative/modal-form-create-question-alternative.component";
import { ModalFormAnswerListComponent } from "./modals/modal-form-answer-list/modal-form-answer-list.component";
import { ModalFormPermissionsComponent } from "./modals/modal-form-permissions/modal-form-permissions.component";
import { ModalDoctorVinculateComponent } from "./modals/modal-doctor-vinculate/modal-doctor-vinculate.component";
import { VinculateDoctorComponent } from "./vinculate_doctor/vinculate_doctor.component";
import { MatSortModule } from "@angular/material/sort";

import { ModalFormQuestionCaptionComponent } from "./modals/modal-form-question-caption/modal-form-question-caption";
import { AdminComponent } from "./admin/admin.component";
import { NotAllowedComponent } from "./not-allowed/not-allowed.component";
import { ChartComponent } from "./patient/components/formularios/chart.component";
import { CompanyAdminComponent } from "./admin/company-admin/company-admin.component";
import { FormAdminComponent } from "./admin/form-admin/form-admin.component";
import { ModalFormAdminComponent } from "./admin/form-admin/modal-form-admin/modal-form-admin.component";
import { FormModelAdminComponent } from "./admin/form-model-admin/form-model-admin.component";
import { ModalFormModelListComponent } from "./modals/modal-form-model-list/modal-form-model-list.component";
import { ModalFormModelDetailComponent } from "./modals/modal-form-model-list/modal-form-model-detail/modal-form-model-detail.component";
import { ModalDoctorNotificationComponent } from "./modals/modal-doctor-notification/modal-doctor-notification.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { DiagnosisComponent } from "./admin/diagnosis/diagnosis.component";
import { ModalDiagnosisomponent } from "./admin/diagnosis/modal-diagnosis/modal-diagnosis.component";
import { ModalDiagnosisIndicatoromponent } from "./admin/diagnosis/modal-diagnosis-indicator/modal-diagnosis-indicator.component";
import { ModalDiagnosisNicNocComponent } from "./admin/diagnosis/modal-diagnosis-nic-noc/modal-diagnosis-nic-noc.component";
import { ModalDiagnosisClassComponent } from "./admin/diagnosis/modal-diagnosis-class/modal-diagnosis-class.component";
import { ModalDiagnosisDomainComponent } from "./admin/diagnosis/modal-diagnosis-domain/modal-diagnosis-domain.component";
import { ModalFormPermissionsDiagnosisComponent } from "./modals/modal-form-permissions/modal-form-permissions-diagnosis/modal-form-permissions-diagnosis.component";
import { ModalFormPermissionsDiagnosisAddComponent } from "./modals/modal-form-permissions/modal-form-permissions-diagnosis/modal-form-permissions-diagnosis-add/modal-form-permissions-diagnosis-add.component";
import { ModalFormPermissionsDiagnosisInformationComponent } from "./modals/modal-form-permissions/modal-form-permissions-diagnosis/modal-form-permissions-diagnosis-information/modal-form-permissions-diagnosis-information.component";
import { CorePlanComponent } from "./core-plan/core-plan.component";
import { ModalCorePlanCreateComponent } from "./modals/modal-core-plan-create/modal-core-plan-create.component";
import { ModalCorePlanPatientsComponent } from "./modals/modal-core-plan-patients/modal-core-plan-patients.component";
import { ModalCorePlanPatientVinculateComponent } from "./modals/modal-core-plan-patient-vinculate/modal-core-plan-patient-vinculate.component";
import { ModalCorePlanCloseComponent } from "./modals/modal-core-plan-close/modal-core-plan-close.component";
import { ReportDayFormsComponent } from "./report-day-forms/report_day-forms.component";
import { TextMaskModule } from "angular2-text-mask";
import { ScoreAnswersComponent } from "./scoreanswers/scoreanswers.component";
import { ModalScoreAnswersCreateComponent } from "./modals/modal-scoreanswers-create/modal-scoreanswers-create.component";
import { ModalScoreAnswersFormComponent } from "./modals/modal-scoreanswers-form/modal-scoreanswers-form.component";
import { NotificationsAdminComponent } from "./admin/notifications-admin/notifications-admin.component";
import { ModalCompanySettingsAdminComponent } from "./admin/modal-company-settings-admin/modal-company-settings-admin.component";
import { ScoreAnswerAdminComponent } from "./admin/scoreanswer-admin/scoreanswer-admin.component";
import { ScoreAnswerModelAdminComponent } from "./admin/scoreanswer-model-admin/scoreanswer-model-admin.component";
import { ModalScoreAnswerModelDetailComponent } from "./modals/modal-scoreanswer-model-list/modal-scoreanswer-model-detail/modal-scoreanswer-model-detail.component";
import { ModalScoreAnswerModelListComponent } from "./modals/modal-scoreanswer-model-list/modal-scoreanswer-model-list.component";
import { ModalPatientResponsablesComponent } from "./modals/modal-patient-responsables/modal-patient-responsables.component";
import { ModalPatientResponsableDetailComponent } from "./modals/modal-patient-responsable-detail/modal-patient-responsable-detail.component";
import { FormulariosAllComponent } from "./patient/components/formularios-all/formularios-all.component";
import { ModalDoctorSelectCompanyComponent } from "./modals/modal-doctor-select-company/modal-doctor-select-company.component";
import { CompanyInterceptor } from "./service/company.interceptor";
import { CalendarComponent } from "./calendar/calendar.component";
import { SideBarScreenComponent } from "./sidebar/sidebar.component";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { ModalAddCalendarComponent } from "./calendar/modal-add-calendar/modal-add-calendar.component";
import { ModalSelectServiceTypeComponent } from "./navbar/modal-select-service/modal-select-service.component";
import { SettingsClinicComponent } from "./settings-clinic/settings-clinic.component";
import { ProcedureSettingsComponent } from "./settings-clinic/procedure-settings/procedure-settings.component";
import { ModalProcedureSettingsComponent } from "./settings-clinic/procedure-settings/modal-procedure-settings/modal-procedure-settings.component";

import { ColorSketchModule } from "ngx-color/sketch";
import { CalendarSettingsComponent } from "./settings-clinic/calendar-settings/calendar-settings.component";
import { ServiceTypeSettingsComponent } from "./settings-clinic/service-type-settings/service-type-settings.component";
import { ModalServiceTypeSettingsComponent } from "./settings-clinic/service-type-settings/modal-service-type-settings/modal-service-type-settings.component";
import { AddRecordClinicComponent } from "./patient/components/sinais-vitais-clinic/add-record-clinic.component";
import { AddMedicationClinicComponent } from "./patient/components/medicamentos-clinic/add-medication-clinic.component";
import { AddExamClinicComponent } from "./patient/components/exames-clinic/add-exam-clinic.component";
import { AddAnswerFormClinicComponent } from "./patient/components/formularios-clinic/add-answer-form-clinic.component";
import { ModalServiceTypeSettingsExtraComponent } from "./patient/components/modals/modal-service-type-settings-extra/modal-service-type-settings-extra.component";
import { ModalRecoveryComponent } from "./modals/modal-recovery/modal-recovery.component";
import { ProntuarioCompletoComponent } from "./patient/components/prontuario-completo/prontuario-completo.component";
import { WhatsappSettingsComponent } from "./settings-clinic/whatsapp-settings/whatsapp-settings.component";
import { TelemedicineComponent } from "./telemedicine/telemedicine.component";
registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    NavbarScreenComponent,
    PatientsComponent,
    PatientComponent,
    ModalPatientDetailComponent,
    ModalAddRecordComponent,
    ModalAddMedicationComponent,
    ModalDoctorDetailComponent,
    ModalAddExamComponent,
    ModalAddPatientComponent,
    ModalDoctorCheckinComponent,
    ModalMessageComponent,
    ModalChoiceComponent,
    ModalSelectFormComponent,
    ModalFormCreateComponent,
    ModalFormCreateQuestionComponent,
    ModalFormCreateQuestionAlternativeComponent,
    ModalFormAnswerListComponent,
    ModalDoctorVinculateComponent,
    ModalFillFormComponent,
    DoctorsComponent,
    FormsComponent,
    FormQuestionSelectComponent,
    FormQuestionComponent,
    InformationsComponent,
    ModalSpecialtyComponent,
    ModalAssociationComponent,
    MedicationComponent,
    ModalMedicationDetailComponent,
    ModalFormDetailComponent,
    ModalFormAnswerComponent,
    ModalFormFillComponent,
    ModalFormPermissionsComponent,
    TermsComponent,
    TermsRawComponent,
    ModalPatientMedicationDetailComponent,
    ModalNewPatientMedicationComponent,
    ForgotPasswordComponent,
    DeleteAccountComponent,
    LandingComponent,
    HomeComponent,
    FooterComponent,
    AboutComponent,
    ContactUsComponent,
    HomePatientComponent,
    HomeDoctorComponent,
    HomecareComponent,
    PlansComponent,
    PartnersComponent,
    SinaisVitaisComponent,
    MedicamentosComponent,
    ExamesComponent,
    FormularioComponent,
    PrescricaoComponent,
    ReportDayComponent,
    VinculateDoctorComponent,
    ModalFormQuestionCaptionComponent,
    NotAllowedComponent,
    AdminComponent,
    ChartComponent,
    CompanyAdminComponent,
    FormAdminComponent,
    ModalFormAdminComponent,
    FormModelAdminComponent,
    ModalFormModelListComponent,
    ModalFormModelDetailComponent,
    ModalDoctorNotificationComponent,
    DashboardComponent,
    DiagnosisComponent,
    ModalDiagnosisomponent,
    ModalDiagnosisIndicatoromponent,
    ModalDiagnosisNicNocComponent,
    ModalDiagnosisClassComponent,
    ModalDiagnosisDomainComponent,
    ModalFormPermissionsDiagnosisComponent,
    ModalFormPermissionsDiagnosisAddComponent,
    ModalFormPermissionsDiagnosisInformationComponent,
    CorePlanComponent,
    ModalCorePlanCreateComponent,
    ModalCorePlanPatientsComponent,
    ModalCorePlanPatientVinculateComponent,
    ModalCorePlanCloseComponent,
    ReportDayFormsComponent,
    ScoreAnswersComponent,
    ModalScoreAnswersCreateComponent,
    ModalScoreAnswersFormComponent,
    NotificationsAdminComponent,
    ModalCompanySettingsAdminComponent,
    ScoreAnswerAdminComponent,
    ScoreAnswerModelAdminComponent,
    ModalScoreAnswerModelDetailComponent,
    ModalScoreAnswerModelListComponent,
    ModalPatientResponsablesComponent,
    ModalPatientResponsableDetailComponent,
    FormulariosAllComponent,
    ModalDoctorSelectCompanyComponent,
    CalendarComponent,
    SideBarScreenComponent,
    ModalAddCalendarComponent,
    ModalSelectServiceTypeComponent,
    SettingsClinicComponent,
    ProcedureSettingsComponent,
    ModalProcedureSettingsComponent,
    CalendarSettingsComponent,
    ServiceTypeSettingsComponent,
    ModalServiceTypeSettingsComponent,
    AddRecordClinicComponent,
    AddMedicationClinicComponent,
    AddExamClinicComponent,
    AddAnswerFormClinicComponent,
    ModalServiceTypeSettingsExtraComponent,
    ModalRecoveryComponent,
    ProntuarioCompletoComponent,
    WhatsappSettingsComponent,
    TelemedicineComponent
  ],
  imports: [
    MatSnackBarModule,
    MatSidenavModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatInputModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatTooltipModule,
    MatSelectModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatIconModule,
    NgxMaskModule.forRoot(),
    MDBBootstrapModule.forRoot(),
    MatSortModule,
    MatIconModule,
    TextMaskModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    ColorSketchModule,
    
  ],
  providers: [
    DatePipe,
    MatNativeDateModule,
    { provide: MAT_DATE_LOCALE, useValue: "pt-BR" },
    { provide: LOCALE_ID, useValue: "pt" },
    PrescricoesService,
    { provide: HTTP_INTERCEPTORS, useClass: CompanyInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
